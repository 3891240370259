import React from 'react';
import { motion } from 'framer-motion';
import { FaCheckCircle } from 'react-icons/fa';

const Differentiators = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6 },
    },
  };

  const differentiators = [
    {
      title: "Result-Oriented Approach",
      description: "At GGDC, results matter. We are driven by a relentless focus on outcomes, measuring our success by the tangible value we deliver to our clients. Whether it's accelerating project timelines, improving operational efficiency, or driving revenue growth, we prioritize actions and initiatives that yield measurable benefits and drive sustainable business performance. By aligning our efforts with our clients' goals and objectives, we ensure that every engagement generates meaningful and impactful results."
    },
    {
      title: "On-Ground Deployment and Execution Support",
    description:"At GGDC, we go beyond providing strategic advice; we roll up our sleeves and work alongside our clients to ensure that strategies are effectively implemented on the ground. Our hands-on approach involves deploying experienced consultants who actively engage with project teams, offering guidance, support, and practical solutions to drive successful execution. By bridging the gap between strategy and implementation, we empower our clients to achieve tangible results and sustainable growth."
    },
    {
      title: "Project, Program, and Portfolio Management Best Practices in Planning, Execution, and Alignment with Strategy:",
      description:"We are committed to upholding the highest standards of project, program, and portfolio management throughout the project lifecycle. Our team leverages industry-leading best practices, methodologies, and tools to meticulously plan, execute, and monitor initiatives, ensuring alignment with overarching strategic objectives. By adopting a holistic approach to management, we optimize resource allocation, mitigate risks, and enhance organizational agility, enabling our clients to deliver projects on time, within budget, and with maximum impact."
    },
    {
      title: "Planned Stakeholder Engagement",
      description: "Stakeholder engagement is a critical component of our approach. We recognize the importance of actively involving and aligning all relevant stakeholders throughout the project lifecycle. Our structured stakeholder engagement plans ensure that key stakeholders are identified, their interests and expectations are understood, and tailored strategies are implemented to foster buy-in and support. By proactively managing stakeholder relationships, we minimize resistance, mitigate risks, and maximize project success."
    }
  ];

  return (
    <motion.div
      id="differentiators"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      className="py-20 bg-gradient-to-r from-[#F2B9AC] to-[#F2E3E1]"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div variants={itemVariants} className="text-center mb-16">
          <h2 className="text-5xl font-extrabold mb-6" style={{ color: '#402C2B', fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
            Our Differentiators
          </h2>
          <p className="max-w-3xl mx-auto text-xl" style={{ color: '#402C2B', fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
            Discover what sets GGDC apart and why we're the preferred choice for real estate strategy and project portfolio management.
          </p>
        </motion.div>
        <motion.div variants={itemVariants} className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {differentiators.map((diff, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              className="bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-2xl transform hover:-translate-y-1 hover:bg-gradient-to-br hover:from-white hover:to-[#F2E3E1]"
            >
              <div className="p-8 h-full flex flex-col">
                <div className="flex items-center mb-4">
                  <div className="bg-[#8C5A4F] rounded-full p-2 mr-4">
                    <FaCheckCircle className="text-white text-2xl" />
                  </div>
                  <h3 className="text-2xl font-semibold" style={{ color: '#8C5A4F', fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
                    {diff.title}
                  </h3>
                </div>
                <p className="text-lg flex-grow" style={{ color: '#A67063', fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
                  {diff.description}
                </p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Differentiators;
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from './assets/logo.png';
import ArtifexCFDemiBold from './fonts/ArtifexCF-DemiBold.otf';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const [currentSection, setCurrentSection] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const navItems = [
    { id: 'about', label: 'About Us' },
    { id: 'services', label: 'Services' },
    { id: 'differentiators', label: 'Differentiators' },
    { id: 'pandc', label: 'Partnerships' }, 
    { id: 'case-studies', label: 'Case Studies' },
    { id: 'career', label: 'Careers' },
    { id: 'contact', label: 'Contact Us' },
  ];

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      @font-face {
        font-family: 'Artifex CF Demi Bold';
        src: url(${ArtifexCFDemiBold}) format('opentype');
        font-weight: normal;
        font-style: normal;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const checkSection = () => {
    const sections = navItems.map(item => document.getElementById(item.id));
    let current = '';

    sections.forEach(section => {
      if (section) {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (window.pageYOffset >= sectionTop - 100 && window.pageYOffset < sectionTop + sectionHeight - 100) {
          current = section.id;
        }
      }
    });

    setCurrentSection(current);
  };

  useEffect(() => {
    window.addEventListener('scroll', checkSection);
    return () => window.removeEventListener('scroll', checkSection);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollToSection = (sectionId) => {
    if (location.pathname !== '/') {
      navigate('/', { state: { scrollTo: sectionId } });
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
    setActiveSection(sectionId);
    setIsOpen(false);
  };

  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      const element = document.getElementById(location.state.scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      setActiveSection(location.state.scrollTo);
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location, navigate]);

  return (
    <nav className="bg-white shadow-lg sticky top-0 z-50 transition-all duration-300 ease-in-out">
      <style>
        {`
          .nav-font {
            font-family: 'Artifex CF Demi Bold', sans-serif;
          }
          .nav-item {
            position: relative;
            transition: all 0.3s ease;
          }
          .nav-item::after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 0;
            height: 2px;
            background-color: #F2B9AC;
            transition: width 0.3s ease;
          }
          .nav-item:hover::after, .nav-item.active::after {
            width: 100%;
          }
        `}
      </style>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          <div className="flex-shrink-0">
            <Link to="/" className="flex items-center">
              <img src={logo} alt="GGDC Logo" className="h-16 w-auto" />
            </Link>
          </div>
          <div className="flex items-center">
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                {navItems.map((item) => (
                  <button
                    key={item.id}
                    onClick={() => scrollToSection(item.id)}
                    className={`nav-font nav-item text-[#402C2B] hover:text-[#F2B9AC] px-3 py-2 rounded-md text-lg font-semibold transition-colors duration-300 ${
                      currentSection === item.id ? 'active text-[#F2B9AC]' : ''
                    }`}
                  >
                    {item.label}
                  </button>
                ))}
              </div>
            </div>
            {/* Mobile menu button */}
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={toggleMenu}
                type="button"
                className="bg-[#A67063] inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-[#8C5A4F] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#A67063] focus:ring-white transition-colors duration-300"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile menu, show/hide based on menu state */}
      <div className={`${isOpen ? 'block' : 'hidden'} md:hidden`}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          {navItems.map((item) => (
            <button
              key={item.id}
              onClick={() => scrollToSection(item.id)}
              className={`nav-font text-[#402C2B] hover:bg-[#F2B9AC] hover:text-white block px-3 py-2 rounded-md text-base font-medium w-full text-left transition-colors duration-300 ${
                currentSection === item.id ? 'bg-[#F2B9AC] text-white' : ''
              }`}
            >
              {item.label}
            </button>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import CS2Image from './assets/CS2.jpg';
import { motion } from 'framer-motion';
import ArtifexCFDemiBold from './fonts/ArtifexCF-DemiBold.otf';
import FloatingWhatsAppButton from './FloatingWhatsAppButton';

const CaseStudy2 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleContactClick = () => {
    navigate('/'); // Navigate to the main page
    setTimeout(() => {
      document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
    }, 100); // Delay to ensure the navigation is complete
  };

  return (
    <div className="py-20 bg-gradient-to-b from-white to-[#FFF5F2] relative">
      <style>
        {`
          @font-face {
            font-family: 'Artifex CF Demi Bold';
            src: url(${ArtifexCFDemiBold}) format('opentype');
            font-weight: normal;
            font-style: normal;
          }

          body {
            font-family: 'Artifex CF Demi Bold', sans-serif;
          }
        `}
      </style>
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <h1 className="text-5xl font-extrabold mb-8 text-center" style={{ color: '#402C2B' }}>
          Seamless Project Launch: Orchestrating Multi-Departmental Coordination
        </h1>
        <motion.img 
          whileHover={{ scale: 1.05 }}
          src={CS2Image} 
          alt="Seamless Project Launch" 
          className="w-full h-[500px] object-cover rounded-xl mb-12 shadow-2xl" 
        />
        
        {[
          { 
            title: 'Background',
            content: `We embarked on a groundbreaking project to launch a premium residential complex in a prime urban location. The project involved a multitude of interdependent activities spanning various departments, regulatory agencies, and stakeholders. From obtaining regulatory approvals to marketing pre-launch events We faced the daunting task of orchestrating a seamless launch while ensuring compliance, efficiency, and stakeholder satisfaction.`
          },
          { 
            title: 'Challenges',
            content: [
              "The project launch presented several complex challenges:",
              "1. Multi-Departmental Coordination: Coordinating activities across multiple departments, including legal, finance, marketing, construction, and regulatory compliance, required meticulous planning, communication, and alignment of objectives.",
              "2. Regulatory Approvals and RERA Registration: Navigating the regulatory landscape and obtaining approvals from municipal authorities, environmental agencies, and the Real Estate Regulatory Authority (RERA) was critical to ensuring project compliance and market credibility.",
              "3. Shifting Existing Society and Demolition: Coordinating the relocation of existing society residents, obtaining necessary permissions, and executing demolition activities while minimizing disruptions and ensuring safety were paramount.",
              "4. Pre-Construction Preparation: Developing comprehensive pre-construction plans, including General Floor Construction (GFC) drawings, marketing materials, pricing strategies, and sales plans, required meticulous attention to detail and collaboration with internal and external stakeholders."
            ]
          },
          { 
            title: 'Approach',
            content: [
              "GGDC adopted a strategic approach to address the challenges and ensure a successful project launch:",
              "1. Multi-Departmental Coordination: A dedicated project management team was formed, comprising representatives from all relevant departments. Regular meetings, clear communication channels, and alignment sessions were conducted to ensure synergy, accountability, and progress tracking across departments.",
              "2. Regulatory Approvals and RERA Registration: Leveraging its strong relationships with regulatory bodies and legal expertise, GGDC navigated the approval process efficiently. Proactive engagement, thorough documentation, and compliance with RERA guidelines facilitated timely approvals and registration, enhancing project credibility and investor confidence.",
              "3. Funding Sanction from NBFC: GGDC collaborated closely with financial advisors and legal counsel to prepare comprehensive funding proposals and meet NBFC requirements. Transparent communication, financial transparency, and risk mitigation strategies were key to securing funding within the specified timelines.",
              "4. Shifting Existing Society and Demolition: Working in collaboration with local authorities, GGDC coordinated the smooth relocation of society residents, addressing their concerns and ensuring minimal disruption. Stringent safety measures, environmental compliance, and community engagement initiatives were implemented during the demolition phase to mitigate risks and build trust.",
              "5. Pre-Construction Preparation: A cross-functional team comprising architects, engineers, marketing specialists, and sales experts was tasked with developing pre-construction plans. GFC drawings, marketing materials, pricing strategies, and sales plans were meticulously crafted to align with market dynamics, customer preferences, and project objectives."
            ]
          },
          { 
            title: 'Results',
            content: [
              "The coordinated efforts of GGDC culminated in a successful project launch:",
              "1. Seamless Multi-Departmental Coordination: Clear communication channels, proactive collaboration, and effective project management ensured alignment of efforts and timely execution of activities across departments.",
              "2. Regulatory Compliance and RERA Registration: The project obtained all necessary approvals and was registered with RERA, demonstrating compliance with regulatory requirements and instilling confidence among buyers and investors.",
              "3. Funding Secured from NBFC: Funding was successfully secured from the NBFC, enabling the project to proceed as planned and meet financial obligations.",
              "4. Smooth Society Shifting and Demolition: The relocation of society residents and demolition activities were executed with minimal disruptions and safety incidents, earning praise from stakeholders and regulatory authorities.",
              "5. Pre-Construction Preparation: GFC drawings, marketing materials, pricing strategies, and sales plans were well-received by the market, generating buzz and anticipation for the project launch."
            ]
          },
          { 
            title: 'Conclusion',
            content: `The successful launch of the residential complex exemplified GGDC's ability to navigate complex challenges, foster collaboration, and deliver results. By adopting a strategic and coordinated approach, GGDC demonstrated its commitment to excellence, compliance, and stakeholder satisfaction, setting the stage for a successful project execution and market performance. The case study serves as a testament to the importance of effective multi-departmental coordination and proactive planning in achieving project success in the real estate industry.`
          }
        ].map((section, index) => (
          <motion.div 
            key={section.title}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.2 }}
            className="mb-12"
          >
            <h2 className="text-3xl font-bold mb-4" style={{ color: '#8C5A4F' }}>{section.title}</h2>
            {Array.isArray(section.content) ? (
              <ul className="list-none space-y-4">
                {section.content.map((item, i) => (
                  <li key={i} className="text-lg leading-relaxed text-[#A67063]">{item}</li>
                ))}
              </ul>
            ) : (
              <p className="text-lg leading-relaxed text-[#A67063]">{section.content}</p>
            )}
          </motion.div>
        ))}
        
        <motion.div 
          whileHover={{ scale: 1.05 }}
          className="bg-[#8C5A4F] text-white p-6 rounded-lg shadow-lg"
        >
          <h3 className="text-2xl font-bold mb-2">Ready to launch your project?</h3>
          <p className="mb-4">Let GGDC orchestrate your success story.</p>
          <button 
            className="bg-white text-[#8C5A4F] font-bold py-2 px-4 rounded hover:bg-[#FFF5F2] transition duration-300"
            onClick={handleContactClick}
          >
            Get in Touch
          </button>
        </motion.div>
      </motion.div>
      <FloatingWhatsAppButton/>
    </div>
  );
};

export default CaseStudy2;

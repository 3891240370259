import React, { useRef, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { FaCogs } from 'react-icons/fa';

const Services = () => {
  const controls = useAnimation();
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          controls.start('visible');
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [controls]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6 },
    },
  };

  const services = [
    'Project Management',
    'Program Management',
    'Portfolio Management',
    'Budget Management',
    'Risk Management',
    'Strategic Planning & Execution',
    'Business Development',
    'Market Research',
    'Stakeholder Engagement',
    'Team Training & Development',
    'Financial Analysis',
    'Procurement',
    'Performance Monitoring',
    'Data Analysis',
    'MIS (Management Information Systems)',
    'Business Intelligence',
    'Negotiation',
    'Change Management',
    'Cross-functional Collaboration',
    'Regulatory & Compliance Management',
  ];

  return (
    <motion.div
      ref={ref}
      id="services"
      initial="hidden"
      animate={controls}
      variants={containerVariants}
      className="py-16 md:py-24 bg-gradient-to-r from-[#F2B9AC] to-[#F2E3E1]"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div variants={itemVariants} className="text-center mb-12 md:mb-16">
          <h2 className="text-4xl md:text-5xl font-extrabold mb-4 md:mb-6" style={{ color: '#402C2B', fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
            Our Services
          </h2>
          <p className="max-w-3xl mx-auto text-lg md:text-xl" style={{ color: '#402C2B', fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
            Explore our comprehensive range of services tailored to meet your real estate strategy and project management needs.
          </p>
        </motion.div>
        <motion.div
          variants={itemVariants}
          className="bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-2xl transform hover:-translate-y-1 max-w-4xl mx-auto"
        >
          <div className="p-6 md:p-8">
            <FaCogs className="text-4xl md:text-5xl text-[#8C5A4F] mb-4 md:mb-6 mx-auto" />
            <h3 className="text-xl md:text-2xl font-semibold mb-4 text-center" style={{ color: '#8C5A4F', fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
              Our Expertise
            </h3>
            <ul className="text-base md:text-lg grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-4" style={{ color: '#A67063', fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
              {services.map((service, index) => (
                <li key={index} className="flex items-center">
                  <span className="w-2 h-2 bg-[#8C5A4F] rounded-full mr-2 flex-shrink-0"></span>
                  <span className="flex-grow">{service}</span>
                </li>
              ))}
            </ul>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Services;
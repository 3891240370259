import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaUser, FaEnvelope, FaRegCommentDots } from 'react-icons/fa';

const Contact = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [submitStatus, setSubmitStatus] = useState('');
  const [showThankYou, setShowThankYou] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const googleFormUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSe9uDT0owAXOXqfw2_BoL5uOLOrkyNKUqUBMMUaFYEO9L6kGQ/formResponse';

    const formDataToSubmit = new FormData();
    formDataToSubmit.append('entry.1377153742', formData.name);
    formDataToSubmit.append('entry.1482861242', formData.email);
    formDataToSubmit.append('entry.2017347459', formData.message);

    fetch(googleFormUrl, {
      method: 'POST',
      body: formDataToSubmit,
      mode: 'no-cors'
    })
      .then(() => {
        setFormData({ name: '', email: '', message: '' });
        setSubmitStatus('Form submitted successfully!');
        setShowThankYou(true);
      })
      .catch(() => {
        setSubmitStatus('There was an error submitting the form. Please try again.');
      });
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6 },
    },
  };

  if (showThankYou) {
    return (
      <motion.div
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        className="py-24 bg-gradient-to-r from-[#F2B9AC] to-[#F2E3E1] flex items-center justify-center"
      >
        <motion.div
          variants={itemVariants}
          className="bg-white rounded-2xl shadow-lg p-10 text-center"
        >
          <h2 className="text-3xl font-bold mb-4 text-[#402C2B]" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
            Thank You!
          </h2>
          <p className="text-xl text-[#614440]" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
            Your message has been received. We'll get back to you soon!
          </p>
        </motion.div>
      </motion.div>
    );
  }

  return (
    <motion.div
      id="contact"
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={containerVariants}
      className="py-24 bg-gradient-to-r from-[#F2B9AC] to-[#F2E3E1]"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div className="text-center mb-20" variants={itemVariants}>
          <h2 className="text-5xl font-extrabold mb-6 text-[#402C2B] leading-tight" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
            Get in Touch
          </h2>
          <p className="mt-4 text-xl text-[#614440] max-w-2xl mx-auto" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
            We're excited to hear from you! Whether you have a question, feedback, or just want to say hello, drop us a message below.
          </p>
        </motion.div>
        <motion.div
          variants={itemVariants}
          className="bg-white rounded-2xl shadow-lg p-10 transform transition duration-500 hover:shadow-2xl relative"
        >
          <div className="absolute top-0 left-0 w-16 h-16 bg-[#F2B9AC] rounded-br-full opacity-30"></div>
          <div className="absolute bottom-0 right-0 w-16 h-16 bg-[#F2B9AC] rounded-tl-full opacity-30"></div>
          <form className="max-w-lg mx-auto space-y-6" onSubmit={handleSubmit}>
            <motion.div className="relative" variants={itemVariants}>
              <FaUser className="absolute top-1/2 transform -translate-y-1/2 left-3 text-[#8C5A4F]" />
              <label htmlFor="name" className="block text-sm font-medium text-[#614440] mb-1" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>Name</label>
              <input
                id="name"
                name="name"
                type="text"
                placeholder="Your name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full pl-10 p-3 border border-[#D9A296] rounded-lg focus:ring-2 focus:ring-[#8C5A4F] focus:border-transparent transition duration-300"
                style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}
              />
            </motion.div>
            <motion.div className="relative" variants={itemVariants}>
              <FaEnvelope className="absolute top-1/2 transform -translate-y-1/2 left-3 text-[#8C5A4F]" />
              <label htmlFor="email" className="block text-sm font-medium text-[#614440] mb-1" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>Email</label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="your@email.com"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full pl-10 p-3 border border-[#D9A296] rounded-lg focus:ring-2 focus:ring-[#8C5A4F] focus:border-transparent transition duration-300"
                style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}
              />
            </motion.div>
            <motion.div className="relative" variants={itemVariants}>
              <FaRegCommentDots className="absolute top-1/2 transform -translate-y-1/2 left-3 text-[#8C5A4F]" />
              <label htmlFor="message" className="block text-sm font-medium text-[#614440] mb-1" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>Message</label>
              <textarea
                id="message"
                name="message"
                placeholder="Your message here..."
                value={formData.message}
                onChange={handleInputChange}
                className="w-full pl-10 p-3 border border-[#D9A296] rounded-lg focus:ring-2 focus:ring-[#8C5A4F] focus:border-transparent transition duration-300"
                rows="5"
                style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}
              ></textarea>
            </motion.div>
            <motion.button
              variants={itemVariants}
              type="submit"
              className="w-full py-4 px-6 border border-transparent rounded-lg shadow-sm text-lg font-semibold text-white bg-gradient-to-r from-[#8C5A4F] to-[#402C2B] hover:from-[#402C2B] hover:to-[#8C5A4F] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#8C5A4F] transition duration-300 transform hover:scale-105"
              style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}
            >
              Send Message
            </motion.button>
          </form>
          {submitStatus && (
            <div className="mt-4 text-center text-green-600">
              {submitStatus}
            </div>
          )}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Contact;

import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import Banner from './Banner';
import About from './About';
import Services from './Services';
import Contact from './Contact';
import Testimonials from './testimonials'; // Corrected case
import Footer from './footer'; // Corrected case
import CaseStudies from './casestudies'; // Corrected case
import CaseStudy1 from './CaseStudy1';
import CaseStudy2 from './CaseStudy2';
import './fonts/ArtifexCF-DemiBold.otf';
import FloatingWhatsAppButton from './FloatingWhatsAppButton';
import Differentiators from './differentiators';
import PartnershipsCollaborations from './pandc';
import CareerSection from './career';

function App() {
  return (
    <Router>
      <div className="bg-white">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/case-study/1" element={<CaseStudy1 />} />
          <Route path="/case-study/2" element={<CaseStudy2 />} />
          {/* Add more routes here as needed */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

const Home = () => (
  <>
    <Banner />
    <div id="about">
      <About />
    </div>
    <div id="services">
      <Services />
    </div>
    <div id="differentiators">
      <Differentiators/>
    </div>
    <div id="pandc">
      <PartnershipsCollaborations/>
    </div>
    <div id="case-studies">
      <CaseStudies />
    </div>
    <div id="testimonials">
      <Testimonials />
    </div>
    <div id="career">
      <CareerSection/>
    </div>
    <div id="contact">
      <Contact />
    </div>
    <FloatingWhatsAppButton />
  </>
);

export default App;

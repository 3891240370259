import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CS1Image from './assets/CS1.jpg';
import { motion } from 'framer-motion';
import ArtifexCFDemiBold from './fonts/ArtifexCF-DemiBold.otf';
import FloatingWhatsAppButton from './FloatingWhatsAppButton';

const CaseStudy1 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleContactClick = () => {
    navigate('/');
    setTimeout(() => {
      document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  return (
    <div className="py-20 bg-gradient-to-b from-white to-[#FFF5F2] relative">
      <style>
        {`
          @font-face {
            font-family: 'Artifex CF Demi Bold';
            src: url(${ArtifexCFDemiBold}) format('opentype');
            font-weight: normal;
            font-style: normal;
          }

          body {
            font-family: 'Artifex CF Demi Bold', sans-serif;
          }
        `}
      </style>
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <h1 className="text-5xl font-extrabold mb-8 text-center" style={{ color: '#402C2B' }}>
          Revitalizing a Stuck Construction Project
        </h1>
        <motion.img 
          whileHover={{ scale: 1.05 }}
          src={CS1Image} 
          alt="Revitalizing a Stuck Construction Project" 
          className="w-full h-[500px] object-cover rounded-xl mb-12 shadow-2xl" 
        />
        
        <motion.div 
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.2 }}
          className="mb-12"
        >
          <h2 className="text-3xl font-bold mb-4" style={{ color: '#8C5A4F' }}>Background</h2>
          <p className="text-lg leading-relaxed text-[#A67063] mb-4">
            A prominent developer approached us to rescue a stalled construction project in a prime urban
            location. The project, a mixed-use development comprising residential and commercial spaces, had encountered delays
            and financial challenges, jeopardizing its viability and reputation. Facing pressure from stakeholders and investors,
            the developer sought expert assistance to turn the project around and deliver it within budget and on schedule.
          </p>
        </motion.div>

        <motion.div 
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.4 }}
          className="mb-12"
        >
          <h2 className="text-3xl font-bold mb-4" style={{ color: '#8C5A4F' }}>Challenges</h2>
          <p className="text-lg leading-relaxed text-[#A67063] mb-4">
            The project faced several critical challenges:
            <ul className="list-disc pl-6 mt-2">
              <li>Stakeholder Management: Diverse stakeholders, including investors, lenders, contractors, and regulatory authorities,
              demanded effective communication, transparency, and alignment of interests.</li>
              <li>Funding Constraints: The project suffered from a shortage of funds due to cost overruns, delayed sales, and reluctance
              from investors to inject additional capital.</li>
              <li>Budgeted Utilization of Funds: Previous mismanagement had resulted in inefficient allocation of resources and budgetary
              discrepancies, requiring meticulous planning and oversight to optimize spending and ensure cost control.</li>
            </ul>
          </p>
        </motion.div>

        <motion.div 
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.6 }}
          className="mb-12"
        >
          <h2 className="text-3xl font-bold mb-4" style={{ color: '#8C5A4F' }}>Approach</h2>
          <p className="text-lg leading-relaxed text-[#A67063] mb-4">
            GGDC devised a comprehensive strategy to address the project's challenges and expedite its turnaround:
            <ul className="list-disc pl-6 mt-2">
              <li>Stakeholder Engagement: Establishing clear lines of communication, GGDC fostered trust and collaboration
              among stakeholders, ensuring alignment of objectives and expectations. Regular progress updates, transparent reporting,
              and proactive issue resolution strengthened stakeholder confidence and commitment.</li>
              <li>New Funding Strategy: Leveraging its extensive network and industry expertise, GGDC facilitated negotiations with
              potential investors and financial institutions to secure additional funding. A structured funding plan was devised,
              outlining utilization milestones and performance benchmarks to instill confidence and mitigate risk.</li>
              <li>Planned and Budgeted Utilization of Funds: GGDC conducted a thorough review of project finances, identifying
              inefficiencies and areas for optimization. A revised budget and expenditure plan were developed, prioritizing critical activities
              and ensuring prudent utilization of funds to maximize value and minimize waste.</li>
              <li>Fast-Tracking Construction and Sales: By implementing agile construction methodologies and streamlining workflows, GGD
              & Co accelerated construction progress while maintaining quality and safety standards. Simultaneously, targeted
              marketing campaigns and innovative sales strategies were employed to attract buyers and expedite unit sales, injecting
              much-needed liquidity into the project.</li>
            </ul>
          </p>
        </motion.div>

        <motion.div 
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.8 }}
          className="mb-12"
        >
          <h2 className="text-3xl font-bold mb-4" style={{ color: '#8C5A4F' }}>Results</h2>
          <p className="text-lg leading-relaxed text-[#A67063] mb-4">
            Through strategic intervention and meticulous execution, GGDC achieved remarkable results:
            <ol className="list-decimal pl-6 mt-2">
              <li>Stakeholder Satisfaction: Effective stakeholder management fostered trust and collaboration, enhancing project credibility and
              support.</li>
              <li>Secured Funding: GGDC successfully secured new funding sources, alleviating financial pressure and enabling the project to
              proceed without disruption.</li>
              <li>Budget Adherence: The revised budget and expenditure plan were meticulously followed, resulting in improved cost control and
              optimized resource utilization.</li>
              <li>Accelerated Construction and Sales: Agile construction practices and targeted sales efforts enabled the project to catch up
              on lost time, with construction progress and sales velocity exceeding expectations.</li>
              <li>On-Time, On-Budget Delivery: Despite initial setbacks, the project was delivered within the revised budget and schedule,
              exceeding stakeholders' expectations and restoring confidence in the developer's capabilities.</li>
            </ol>
          </p>
        </motion.div>

        <motion.div 
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 1 }}
          className="mb-12"
        >
          <h2 className="text-3xl font-bold mb-4" style={{ color: '#8C5A4F' }}>Conclusion</h2>
          <p className="text-lg leading-relaxed text-[#A67063] mb-4">
            The successful turnaround of the stalled construction project underscored the value of strategic planning, effective stakeholder
            management, and agile execution. By partnering with GGDC, the developer was able to overcome challenges, unlock value, and
            deliver a high-quality project that met stakeholder expectations and market demands. The case study serves as a testament to the
            transformative impact of proactive consultancy services in revitalizing distressed real estate projects and driving long-term
            success.
          </p>
        </motion.div>
        
        <motion.div 
          whileHover={{ scale: 1.05 }}
          className="bg-[#8C5A4F] text-white p-6 rounded-lg shadow-lg"
        >
          <h3 className="text-2xl font-bold mb-2">Ready to revitalize your project?</h3>
          <p className="mb-4">Let GGDC turn your challenges into success stories.</p>
          <button 
            onClick={handleContactClick} 
            className="bg-white text-[#8C5A4F] font-bold py-2 px-4 rounded hover:bg-[#FFF5F2] transition duration-300"
          >
            Contact Us
          </button>
        </motion.div>
      </motion.div>
      
      <FloatingWhatsAppButton />
    </div>
  );
};

export default CaseStudy1;

import React from 'react';
import { motion } from 'framer-motion';
import { FaHandshake, FaBuilding, FaChartLine, FaPencilRuler, FaTools, FaClipboardCheck, FaMoneyBillWave, FaBullhorn, FaLeaf, FaLaptopCode } from 'react-icons/fa';

const PartnershipsCollaborations = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6 },
    },
  };

  const domains = [
    { icon: FaHandshake, text: "Land Acquisition and Development, Demerger, Merger" },
    { icon: FaChartLine, text: "Project Management Consultation and Execution" },
    { icon: FaPencilRuler, text: "Design and Architecture" },
    { icon: FaTools, text: "Construction Value Engineering" },
    { icon: FaClipboardCheck, text: "Regulatory Compliance and Permitting" },
    { icon: FaMoneyBillWave, text: "Financial Planning and Fund Raising" },
    { icon: FaBullhorn, text: "Marketing and Sales Strategy" },
    { icon: FaLeaf, text: "Sustainability and Green Building Practices" },
    { icon: FaLaptopCode, text: "IT Systems Implementation, Supply & Maintenance" },
  ];

  return (
    <motion.div
      id="pandc"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      className="py-20 bg-gradient-to-r from-[#F2B9AC] to-[#F2E3E1]"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div variants={itemVariants} className="text-center mb-16">
          <h2 className="text-5xl font-extrabold mb-6" style={{ color: '#402C2B', fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
            Partnerships and Collaborations
          </h2>
          <p className="max-w-3xl mx-auto text-xl mb-8" style={{ color: '#402C2B', fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
            At GGDC, we recognize the immense value of partnerships and collaborations across all domains of the real estate development and construction business. By fostering strategic alliances and synergistic relationships, we enhance our capabilities, drive innovation, and deliver exceptional value to our clients and stakeholders.
          </p>
        </motion.div>
        
        <motion.div variants={itemVariants} className="mb-16">
          <h3 className="text-3xl font-bold mb-8 text-center" style={{ color: '#8C5A4F', fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
            Domains of Expertise of our Partners and Collaborators:
            <span className="block text-2xl mt-2 text-[#A67063]">More than 20 Industry Leaders</span>
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {domains.map((domain, index) => (
              <motion.div
                key={index}
                variants={itemVariants}
                className="bg-white rounded-xl shadow-lg p-6 transition-all duration-300 hover:shadow-2xl transform hover:-translate-y-1 hover:bg-gradient-to-br hover:from-white hover:to-[#F2E3E1] flex items-center"
              >
                <domain.icon className="text-3xl mr-4 text-[#8C5A4F]" />
                <p className="text-lg font-semibold" style={{ color: '#402C2B', fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>{domain.text}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>

        <motion.div variants={itemVariants} className="text-center">
          <p className="max-w-4xl mx-auto text-xl" style={{ color: '#402C2B', fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
            At GGDC, partnerships and collaborations are fundamental pillars of our business strategy, enabling us to leverage external expertise, resources, and networks to drive innovation, expand our market presence, and deliver exceptional outcomes across all domains of real estate development and construction. By fostering a culture of collaboration, trust, and mutual respect, we build enduring relationships that fuel our growth, resilience, and success in a dynamic and competitive industry landscape.
          </p>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default PartnershipsCollaborations;
import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import CS1Image from './assets/CS1.jpg';
import CS2Image from './assets/CS2.jpg';

const caseStudies = [
  {
    id: 1,
    title: "Revitalizing a Stuck Construction Project",
    description: "A prominent developer approached us to rescue a stalled construction project in a prime urban location.",
    image: CS1Image
  },
  {
    id: 2,
    title: "Seamless Project Launch: Orchestrating Multi-Departmental Coordination",
    description: "We embarked on a groundbreaking project to launch a premium residential complex in a prime urban location.",
    image: CS2Image
  }
];

const itemVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.8, ease: "easeOut" },
  },
};

const CaseStudyCard = ({ id, title, description, image }) => (
  <motion.div
    variants={itemVariants}
    className="bg-white rounded-lg shadow-lg overflow-hidden transform transition duration-500 hover:scale-105 hover:shadow-xl"
  >
    <div className="relative">
      <img src={image} alt={title} className="w-full h-64 object-cover" />
      <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-70"></div>
      <h3 className="absolute bottom-4 left-4 text-2xl font-bold text-white" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>{title}</h3>
    </div>
    <div className="p-6">
      <p className="text-[#A67063] mb-4" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>{description}</p>
      <motion.div
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <Link
          to={`/case-study/${id}`}
          className="inline-block px-6 py-3 bg-gradient-to-r from-[#8C5A4F] to-[#402C2B] text-white rounded-full hover:from-[#402C2B] hover:to-[#8C5A4F] transition-all duration-300"
          style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}
        >
          Read Full Case Study
        </Link>
      </motion.div>
    </div>
  </motion.div>
);

const CaseStudies = () => {
  const controls = useAnimation();
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          controls.start('visible');
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [controls]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
        delayChildren: 0.4,
      },
    },
  };

  return (
    <motion.div
      id="case-studies"
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
      className="py-24 bg-gradient-to-r from-[#F2B9AC] to-[#F2E3E1]"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div variants={itemVariants} className="text-center mb-20">
          <h2 className="text-5xl font-extrabold mb-6" style={{ color: '#402C2B', fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
            Our Success Stories
          </h2>
          <p className="mt-4 text-xl max-w-2xl mx-auto" style={{ color: '#8C5A4F', fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
            Discover how we've transformed challenges into triumphs for our clients
          </p>
        </motion.div>
        <motion.div variants={itemVariants} className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {caseStudies.map((study) => (
            <CaseStudyCard key={study.id} {...study} />
          ))}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default CaseStudies;
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import imgli from './assets/lin.png';
import imgwh from './assets/whatsapp.png';
import imgig from './assets/ig.png';
import imgtwitter from './assets/twitter.png';

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleScroll = (sectionId) => {
    if (location.pathname === '/') {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.error(`Element with id "${sectionId}" not found`);
      }
    } else {
      navigate('/', { state: { scrollTo: sectionId } });
    }
  };

  const handleSocialClick = (platform) => {
    switch (platform) {
      case 'linkedin':
        window.open('https://www.linkedin.com/company/ggdco/', '_blank');
        break;
      case 'instagram':
        window.open('https://www.instagram.com/ggd_company?igsh=N2g5MXo2NDV4OXdu&utm_source=qr', '_blank');
        break;
      case 'twitter':
        window.open('https://x.com/ggdc_info?s=11', '_blank');
        break;
      default:
        console.error(`Unhandled social platform: ${platform}`);
    }
  };

  const quickLinks = [
    { label: 'About', id: 'about' },
    { label: 'Services', id: 'services' },
    { label: 'Differentiators', id: 'differentiators' },
    { label: 'Partnerships', id: 'pandc' },
    { label: 'Case Studies', id: 'case-studies' },
    { id: 'career', label: 'Careers' },
    { label: 'Contact', id: 'contact' }
  ];

  return (
    <footer className="bg-gradient-to-r from-[#F2B9AC] to-[#F2E3E1] text-[#402C2B] py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-16">
          <div className="space-y-6">
            <h3 className="text-4xl font-bold mb-6 text-[#402C2B] border-b-2 border-[#D9A296] pb-3 inline-block" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>GGDC</h3>
            <p className="text-[#614440] text-lg leading-relaxed" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
              Elevating real estate through strategic insight, meticulous execution, and unwavering excellence.
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-bold mb-6 text-[#402C2B]" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>Quick Links</h3>
            <ul className="space-y-4 text-lg">
              {quickLinks.map((item) => (
                <li key={item.id}>
                  <button
                    onClick={() => handleScroll(item.id)}
                    className="text-[#614440] hover:text-[#402C2B] transition-colors duration-300 hover:underline flex items-center group"
                    style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}
                  >
                    <span className="mr-2 transform group-hover:translate-x-1 transition-transform duration-300">›</span> {item.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-2xl font-bold mb-6 text-[#402C2B]" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>Contact Us</h3>
            <ul className="space-y-4 text-lg">
              <li className="flex items-center text-[#614440] hover:text-[#402C2B] transition-colors duration-300" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
                <span className="mr-3 text-[#402C2B] bg-[#F2B9AC] p-2 rounded-full">👤</span>
                Ginen G Dharamshi
              </li>
              <li className="flex items-center text-[#614440] hover:text-[#402C2B] transition-colors duration-300" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
                <span className="mr-3 text-[#402C2B] bg-[#F2B9AC] p-2 rounded-full">✉️</span>
                ginen@ggdc.in
              </li>
              <li className="flex items-center text-[#614440] hover:text-[#402C2B] transition-colors duration-300" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
                <span className="mr-3 text-[#402C2B] bg-[#F2B9AC] p-2 rounded-full">📞</span>
                +91-9403414000, +91-9819433650
              </li>
              <li className="flex items-start text-[#614440] hover:text-[#402C2B] transition-colors duration-300" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
                <span className="mr-3 text-[#402C2B] bg-[#F2B9AC] p-2 rounded-full">📍</span>
                <span>The Capital, 70, G Block Rd, Bandra Kurla Complex, Bandra East, Mumbai, Maharashtra 400051</span>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-2xl font-bold mb-6 text-[#402C2B]" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>Follow Us</h3>
            <div className="flex space-x-6">
              <button
                onClick={() => handleSocialClick('linkedin')}
                className="text-[#614440] hover:text-[#402C2B] transition-transform duration-300 hover:scale-110"
              >
                <img src={imgli} width="50" height="50" alt="LinkedIn" className="rounded-full shadow-md hover:shadow-lg transition-shadow duration-300" />
              </button>
              
              <a href="https://wa.me/919403414000"
                className="text-[#614440] hover:text-[#402C2B] transition-transform duration-300 hover:scale-110"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={imgwh} width="50" height="50" alt="WhatsApp" className="rounded-full shadow-md hover:shadow-lg transition-shadow duration-300" />
              </a>
              <button
                onClick={() => handleSocialClick('instagram')}
                className="text-[#614440] hover:text-[#402C2B] transition-transform duration-300 hover:scale-110"
              >
                <img src={imgig} width="50" height="50" alt="Instagram" className="rounded-full shadow-md hover:shadow-lg transition-shadow duration-300" />
              </button>
              <button
                onClick={() => handleSocialClick('twitter')}
                className="text-[#614440] hover:text-[#402C2B] transition-transform duration-300 hover:scale-110"
              >
                <img src={imgtwitter} width="50" height="50" alt="Twitter" className="rounded-full shadow-md hover:shadow-lg transition-shadow duration-300" />
              </button>
            </div>
          </div>
        </div>
        <div className="mt-20 border-t border-[#D9A296] pt-8 flex flex-col items-center">
          <p className="text-[#614440] text-lg" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
            &copy; {new Date().getFullYear()} GGDC. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;